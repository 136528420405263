
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <form @submit.prevent="addShippingPackageFiles" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shipping_package_files.shipping_package_path" type="date" :label="$store.getters.language.data.shipping_package_files.shipping_package_path" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="shipped_packages" v-model="shipping_package_files.shipped_package_id" dense  filled outlined  item-text="shipped_package_id"
                                    item-value="shipped_package_id" :return-object="false" :label="$store.getters.language.data.shipped_packages.shipped_package_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.shipping_package_files.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"  show-select v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="shipping_package_file_id">
                    <template v-slot:[`item.shipping_package_file_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/shipping_package_files-list/'+item.shipping_package_file_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectShippingPackageFiles(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteShippingPackageFilesList">{{$store.getters.language.data.shipping_package_files.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.shipping_package_files.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.shipping_package_files.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteShippingPackageFiles(selected_shipping_package_files.shipping_package_file_id)">
                {{$store.getters.language.data.shipping_package_files.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/shipping_package_files.request.js'
    export default {
        data() {
            return {
                shipping_package_files: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_shipping_package_files : {},
                delete_dialog: false,
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.shipping_package_files.shipping_package_path,
                            align: 'start',
                            sortable: true,
                            value: 'shipping_package_path',
                        },
                    {
                        text: this.$store.getters.language.data.shipped_packages.shipped_package_id,
                        align: 'start',
                        sortable: true,
                        value: 'shipped_package_id',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'shipping_package_file_id',
        }
                ],
            }
        },
        computed: {
            shipped_packages(){
                return this.$store.getters.shipped_packages_list
            },
        },
        mounted(){
            this.readShippingPackageFiles();
        },
        methods: {
            addShippingPackageFiles() {
                this.loading_btn = true
                requests.createShippingPackageFiles(this.shipping_package_files).then(r => {
                    if (r.status == 200) {
                        this.shipping_package_files = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'ShippingPackageFiles Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add ShippingPackageFiles',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteShippingPackageFiles(shipping_package_file_id) {
                requests.deleteShippingPackageFiles(shipping_package_file_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.shipping_package_file_id != shipping_package_file_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'ShippingPackageFiles Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteShippingPackageFilesList() {
                let ids = this.selected_rows.map(m => m.shipping_package_file_id)
                requests.deleteShippingPackageFilesList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.shipping_package_file_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' ShippingPackageFiles Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readShippingPackageFiles() {
                this.loading = true
                requests.getAllShippingPackageFiles().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read ShippingPackageFiles',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read ShippingPackageFiles',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectShippingPackageFiles(i) {
                this.selected_shipping_package_files = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    